import React, { useState } from 'react';
import LayoutLogged from "../../components/layout/logged";
import Container from '@material-ui/core/Container';
import PagePadding from '../../components/layout/page-padding';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Button, ButtonGroup, Dialog, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core';
import GeneralErrors from '../../components/form/general-errors';
import { moneyFormat, numberFormat } from '../../services/number';
import EventIcon from '@material-ui/icons/Event';
import DialogContent from '../../components/dialog/common/dialog-content';
import DialogTitle from '../../components/dialog/common/dialog-title';
import { isMonthPeriod, isYearPeriod, isWeekPeriod, isQuarterPeriod, formatObjectToMonthAndYear, formatWithLocale, formatDateTime } from '../../services/date';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  subYears,
  subMonths,
  subWeeks,
  subQuarters,
  startOfWeek,
  addDays,
  startOfQuarter,
  addMonths
} from 'date-fns';
import SalesReport from '../../components/dashboard/reports/sales';
import OpportunitiesReport from '../../components/dashboard/reports/opportunities';
import WalletReport from '../../components/dashboard/reports/wallet';
import PartnerVisitReport from '../../components/dashboard/reports/partner_visit';
import ProjectsReport from '../../components/dashboard/reports/projects';
import { ifNotHaveAccessRedirect } from '../../services/auth';
import NPSReport from '../../components/dashboard/reports/after_sale/nps';
import WithoutCostCenterReport from '../../components/dashboard/reports/after_sale/without_cost_center';
import LeftMenu from '../../components/layout/left-menu';

export default function DashboardIndex() {
  const [errors, setErrors] = useState();
  const [report, setReport] = useState('sales');
  const [period, setPeriod] = useState('month');
  const [periodPosition, setPeriodPosition] = useState(0);
  const [openDetail, setOpenDetail] = useState(false);
  const [filters, setFilters] = useState({});

  const formatPeriodPosition = () => {
    const date = new Date();

    if (isYearPeriod(period)) {
      return subYears(date, periodPosition).getFullYear();
    } else if (isMonthPeriod(period)) {
      return formatObjectToMonthAndYear(subMonths(date, periodPosition));
    } else if (isWeekPeriod(period)) {
      const start = startOfWeek(subWeeks(date, periodPosition), {
        weekStartsOn: 1,
      });

      const end = addDays(start, 6);

      return `${formatWithLocale(start, 'dd/MMMM')} - ${formatWithLocale(end, 'dd/MMMM')}`;
    } else if (isQuarterPeriod(period)) {
      const start = startOfQuarter(subQuarters(date, periodPosition));
      const end = addMonths(start, 2);

      return `${formatWithLocale(start, 'MMMM/yyyy')} - ${formatWithLocale(end, 'MMMM/yyyy')}`;
    } else {
      throw Error(`Invalid period "${period}"`);
    }
  };

  const handleOpenDetail = (items, field, type, suffix) => {
    setOpenDetail({
      items: items,
      field: field,
      type: type,
      suffix: suffix,
    });
  };

  const handleOnFilter = (data) => {
    const newFilters = { ...filters };
    newFilters['responsible_user_id'] = data.users;

    setFilters(newFilters);
  };

  if (ifNotHaveAccessRedirect('dashboard.view')) return <></>;

  return (
    <LayoutLogged title="Dashboard">
      {report !== 'nps' && (
        <LeftMenu
          onFilter={handleOnFilter}
          withUnknownUsers={false}
        ></LeftMenu>
      )}
      <PagePadding>
        <Container>
          {errors && (
            <GeneralErrors errors={errors} />
          )}

          <table style={{
            width: '100%',
            paddingBottom: 20,
          }}>
            <tr>
              <td style={{ textAlign: 'left' }}>
                <Select
                  value={report}
                  onChange={(e) => e.target.value && setReport(e.target.value)}
                  variant="outlined"
                  style={{ marginRight: 10 }}
                  InputProps={{
                    startAdornment: [EventIcon],
                    autoComplete: 'off',
                  }}
                >
                  <ListSubheader>Comercial</ListSubheader>
                  <MenuItem value="sales">Vendas</MenuItem>
                  <MenuItem value="opportunities">Oportunidades</MenuItem>
                  <MenuItem value="projects">Projetos</MenuItem>
                  <MenuItem value="wallet">Carteira</MenuItem>
                  <MenuItem value="partner_visit">Visitas de parceiros</MenuItem>
                  <ListSubheader>Pós venda</ListSubheader>
                  <MenuItem value="nps">NPS (Qualidade)</MenuItem>
                  <MenuItem value="without_cost_center">Financeiro (Sem centro de custo)</MenuItem>
                </Select>

                {report !== 'wallet' && (
                  <Select
                    value={period}
                    onChange={(e) => {
                      setPeriodPosition(0);
                      setPeriod(e.target.value);
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="year">Ano</MenuItem>
                    <MenuItem value="quarter">Trimestre</MenuItem>
                    <MenuItem value="month">Mês</MenuItem>
                    <MenuItem value="week">Semana</MenuItem>
                  </Select>
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                {report !== 'wallet' && (
                  <ButtonGroup size="small">
                    <Button onClick={() => setPeriodPosition(periodPosition + 1)}><ChevronLeftIcon /></Button>
                    <Button>{formatPeriodPosition()}</Button>
                    <Button disabled={periodPosition === 0} onClick={() => setPeriodPosition(periodPosition - 1)}><ChevronRightIcon /></Button>
                  </ButtonGroup>
                )}
              </td>
            </tr>
          </table>

          {period && filters && periodPosition !== undefined && (
            <>
              {report === 'sales' && (
                <SalesReport
                  period={period}
                  periodPosition={periodPosition}
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}

              {report === 'opportunities' && (
                <OpportunitiesReport
                  period={period}
                  periodPosition={periodPosition}
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}

              {report === 'wallet' && (
                <WalletReport
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}

              {report === 'partner_visit' && (
                <PartnerVisitReport
                  period={period}
                  periodPosition={periodPosition}
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}

              {report === 'projects' && (
                <ProjectsReport
                  period={period}
                  periodPosition={periodPosition}
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}

              {report === 'nps' && (
                <NPSReport
                  period={period}
                  periodPosition={periodPosition}
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}

              {report === 'without_cost_center' && (
                <WithoutCostCenterReport
                  period={period}
                  periodPosition={periodPosition}
                  handleOpenDetail={handleOpenDetail}
                  setErrors={setErrors}
                  filters={filters}
                />
              )}
            </>
          )}
        </Container>
      </PagePadding>

      {openDetail && openDetail?.items && openDetail.items.length > 0 && (
        <Dialog
          onClose={() => setOpenDetail(false)}
          open={true}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            title={openDetail.items.length === 1 ? 'Um resultado' : `${openDetail.items.length} resultados`}
            onClose={() => setOpenDetail(false)}
          />
          <DialogContent dividers style={{ minHeight: 400 }}>
            <List dense>
              {openDetail.items.map(item => {
                return (
                  <ListItem dense>
                    <ListItemText
                      primary={
                        <>
                          <a href={`/workflow/card?workflow=${item.workflow}&id=${item.workflow_id}`} target="_blank" style={{ fontSize: 14, color: '#3f51b5', textDecoration: 'none' }}>
                            <AssignmentIcon style={{ fontSize: 13 }} /> {item.name}
                          </a>
                        </>
                      }
                      secondary={formatDateTime(item.date)} />

                    <ListItemSecondaryAction>
                      {openDetail.type === 'money' ? moneyFormat(item[openDetail.field]) : (isNaN(item[openDetail.field]) ? item[openDetail.field] : numberFormat(item[openDetail.field]))} {openDetail.suffix !== undefined && item[openDetail.field] === 1 ? openDetail.suffix?.singular : openDetail.suffix?.plural}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
        </Dialog>
      )}
    </LayoutLogged>
  );
}